<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">手机号</span>
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入"
                            v-model.trim="telNo"
                            clearable
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">微信昵称</span>
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入"
                            v-model.trim="nickname"
                            clearable
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">学员ID</span>
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入"
                            v-model.trim="userId"
                            clearable
                            @change="onUserIdChange"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button type="primary" @click="onPaginationChange('page', 1)">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" width="100" label="手机号">
                <template slot-scope="scope">
                    <span>{{ scope.row.telNo || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="用户信息">
                <template slot-scope="scope">
                    <UserView :key="scope.row.id" :userInfo="scope.row" />
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="报名公开课">
                <template slot-scope="scope">
                    <div class="course_list" v-for="(item, index) in scope.row.userPackageMappings" :key="index">
                        <div>{{ item.coursePackage ? item.coursePackage.title : '' }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="购买商品">
                <template slot-scope="scope">
                    <div class="product_list" v-for="(item, index) in scope.row.productList" :key="index">
                        <div>{{ item.title }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-link v-if="P(1050101)" type="primary" @click="onShowUserOrderList(scope.row)">用户账单</el-link>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, sizes"
            :total="total"
        />

        <UserOrderList :isShow.sync="isShowUserOrderList" :activeItem="activeItem" @onClose="onCloseUserOrderList" />
    </div>
</template>
<script>
import UserView from '@/views/components/UserView'
import UserOrderList from './components/userList/UserOrderList.vue'
import { userInfoList } from '@/js/api/userApi.js'
import { filterQueryEmpty, isUserId } from '@/js/utils.js'

export default {
    components: { UserView, UserOrderList },
    data() {
        return {
            telNo: '',
            userId: '',
            nickname: '',

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowUserOrderList: false,
        }
    },
    created() {
        this.userInfoListApi()
    },
    methods: {
        onUserIdChange() {
            if (this.userId && !isUserId(this.userId)) {
                this.userId = ''
                this.$message.warning('请输入正确的 学员ID')
            }
        },
        onReset() {
            this.telNo = ''
            this.userId = ''
            this.nickname = ''
            this.onPaginationChange('page', 1)
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.userInfoListApi)
        },

        onShowUserOrderList(item) {
            this.activeItem = item
            this.isShowUserOrderList = true
        },
        onCloseUserOrderList() {
            this.activeItem = null
            this.isShowUserOrderList = false
        },

        userInfoListApi() {
            const { page, pageSize, telNo, userId, nickname } = this
            let params = { page, size: pageSize, showPage: true, orderBy: '-id', telNo, nickname }
            if (userId) params.userIds = [userId]

            params = filterQueryEmpty(params)
            userInfoList(params).then((res) => {
                this.tableData = res.data.list
                this.total = res.data.total
                this.page = res.data.pageNum
            })
        },
    },
}
</script>
<style lang="less" scoped>
.course_list,
.product_list {
    line-height: 1.6em;
    margin-bottom: 12px;
}
</style>
