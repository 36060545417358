<template>
    <el-dialog
        v-if="isShow"
        title="订单录入"
        :visible.sync="isShow"
        width="400px"
        :before-close="onClose"
        @opened="onOpened"
    >
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="100px">
            <el-form-item label="商品" prop="productNo">
                <ProductSelect
                    :defaultValue="formData.productNo"
                    :clearable="true"
                    :disabled="!!activeOrder"
                    @change="(val) => (formData.productNo = val)"
                />
            </el-form-item>
            <el-form-item label="支付金额" prop="paidFee">
                <el-input-number
                    :disabled="!!activeOrder"
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="formData.paidFee"
                />
            </el-form-item>
            <el-form-item label="商品总价" prop="totalFee">
                <el-input-number
                    :disabled="!!activeOrder"
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="formData.totalFee"
                />
            </el-form-item>
            <el-form-item label="支付渠道" prop="provider">
                <el-select filterable v-model="formData.provider" placeholder="请选择">
                    <el-option
                        v-for="item in orderProviderList"
                        :key="item.providerNo"
                        :label="item.providerName"
                        :value="item.providerNo"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="支付时间" prop="paidTime">
                <el-date-picker
                    class="el-input-220"
                    v-model="formData.paidTime"
                    type="datetime"
                    placeholder="请选择"
                    value-format="yyyy-MM-dd HH:mm:ss"
                />
            </el-form-item>
            <el-form-item label="归属运营" prop="sourceFrom">
                <StaffSelect
                    :defaultValue="formData.sourceFrom"
                    :clearable="true"
                    @change="(val) => (formData.sourceFrom = val)"
                />
            </el-form-item>
            <el-form-item label="学员姓名" prop="userContact.name">
                <el-input
                    v-model.trim="formData.userContact.name"
                    :maxlength="32"
                    show-word-limit
                    placeholder="请输入学员姓名"
                    clearable
                />
            </el-form-item>
            <el-form-item label="学员联系方式" prop="userContact.telNo">
                <el-input
                    v-model.trim="formData.userContact.telNo"
                    :maxlength="11"
                    show-word-limit
                    placeholder="请输入学员联系方式"
                    clearable
                />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import StaffSelect from '@/views/components/StaffSelect'
import ProductSelect from '@/views/components/ProductSelect'
import { filterQueryEmpty } from '@/js/utils.js'
import { fillingOrder } from '@/js/api/userApi.js'
import { getProviders } from '@/js/api/shopApi.js'
import { numToCent } from '@/js/numTools.js'

export default {
    props: ['isShow', 'userInfo', 'activeOrder'],
    components: { StaffSelect, ProductSelect },
    data() {
        return {
            formData: null,
            rules: {
                productNo: [{ required: true, message: '请选择商品', trigger: 'blur' }],
                paidTime: [{ required: true, message: '请选择支付时间', trigger: 'blur' }],
            },
            orderProviderList: [],
        }
    },
    methods: {
        onOpened() {
            let formData = {
                productNo: '',
                paidFee: 0,
                totalFee: 0,
                provider: '',
                sourceFrom: '',
                paidTime: '',
                userContact: { name: '', telNo: '' },
            }
            if (this.activeOrder) {
                formData.productNo = this.activeOrder.productNo
                formData.paidFee = (this.activeOrder.totalFee - this.activeOrder.paidFee) / 100
                formData.totalFee = this.activeOrder.totalFee / 100
            }

            this.formData = formData
            this.getProvidersApi()
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        paidFee: numToCent(this.formData.paidFee),
                        totalFee: numToCent(this.formData.totalFee),
                        userId: this.userInfo.id,
                    }
                    params = filterQueryEmpty(params)
                    fillingOrder(params).then(() => {
                        this.$message.success('录入成功')
                        this.$emit('onClose', true)
                    })
                }
            })
        },
        getProvidersApi() {
            getProviders({}).then((res) => {
                this.orderProviderList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-select,
.el-input-number,
.el-date-editor {
    width: 100%;
}
</style>
