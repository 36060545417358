<template>
    <div>
        <el-drawer
            :visible.sync="isShow"
            :before-close="onClose"
            append-to-body
            :title="`${activeItem && activeItem.nickname}`"
            size="720px"
            @opened="onOpened"
        >
            <div>
                <el-button @click="isShowSupplyOrder = true" type="primary">全款录单</el-button>
            </div>
            <br />
            <el-table :data="tableData" stripe border>
                <el-table-column label="商品">
                    <template slot-scope="scope">
                        <span v-if="scope.row.productInfo.id">{{ scope.row.productInfo.title }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="已支付" width="80">
                    <template slot-scope="scope">
                        <span>¥{{ scope.row.paidFee / 100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="总价" width="80">
                    <template slot-scope="scope">
                        <span>¥{{ scope.row.totalFee / 100 }}</span>
                    </template>
                </el-table-column>

                <el-table-column align="center" label="订单状态" width="100">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status === 'PAID'">{{ orderStatusMap['PAID'] }}</el-tag>
                        <el-tag v-else type="info">{{ orderStatusMap[scope.row.status] || '未知' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="paidTime" label="支付时间" width="96" />
                <el-table-column align="center" label="操作" width="90">
                    <template slot-scope="scope">
                        <el-link
                            v-if="scope.row.paidFee !== 0 && scope.row.status === 'UNPAID'"
                            type="primary"
                            @click="onShowSupplyOrder(scope.row)"
                        >
                            订单补录
                        </el-link>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>

        <SupplyOrder
            :isShow="isShowSupplyOrder"
            @onClose="onCloseSupplyOrder"
            :activeOrder="activeOrder"
            :userInfo="activeItem"
        />
    </div>
</template>
<script>
import SupplyOrder from './SupplyOrder.vue'
import { getTradePayOrder } from '@/js/api/userApi.js'
import { getProductList } from '@/js/api/shopApi.js'
import { orderStatusMap } from '@/js/config/order.js'

export default {
    props: ['isShow', 'activeItem'],
    components: { SupplyOrder },
    data() {
        return {
            orderStatusMap,
            tableData: [],

            activeOrder: null,
            isShowSupplyOrder: false,
        }
    },
    methods: {
        onOpened() {
            this.getTradePayOrderApi()
        },
        onClose() {
            this.$emit('onClose')
        },
        onShowSupplyOrder(item) {
            this.activeOrder = item
            this.isShowSupplyOrder = true
        },
        onCloseSupplyOrder(status) {
            this.activeOrder = null
            this.isShowSupplyOrder = false
            if (status === true) this.getTradePayOrderApi()
        },
        onShowUpgradeOrder(item) {
            this.isShowUpgradeOrder = true
            this.activeOrder = item
        },
        onCloseUpgradeOrder(status) {
            this.isShowUpgradeOrder = false
            this.activeOrder = null
            if (status === true) this.getTradePayOrderApi()
        },

        async getTradePayOrderApi() {
            this.tableData = []
            const res1 = await getTradePayOrder({
                orderBy: '-addtime',
                showPage: false,
                userIds: [this.activeItem.id],
            })
            if (res1.data.length !== 0) {
                const _productNos = []
                res1.data.forEach((i) => {
                    if (!_productNos.includes(i.productNo)) {
                        _productNos.push(i.productNo)
                    }
                })
                const res2 = await getProductList({ productNos: _productNos })
                const _productMap = {}
                res2.data.forEach((item) => {
                    _productMap[item.productNo] = item
                })
                res1.data.forEach((item) => {
                    item.productInfo = _productMap[item.productNo]
                })
            }
            this.tableData = res1.data
        },
    },
}
</script>
